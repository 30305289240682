<template>
  <v-autocomplete
    v-model="select"
    multiple
    chips
    deletable-chips
    label="Keywords"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    cache-items
    clearable
    hide-no-data
    hide-details
    item-text="name"
    item-value="id"
    dense
    solo
  >
  </v-autocomplete>
</template>

<script>
import api from "@/common/api";

export default {
  name: "Keywords",
  data() {
    return {
      loading: false,
      select: null,
      items: [],
      search: null,
    };
  },
  watch: {
    search(v) {
      v && v !== this.select && this.querySelections(v);
    },
    select(v) {
      this.$emit("select", v);
    },
  },
  mounted() {
    this.querySelections();
  },
  methods: {
    onInput() {
      console.log("onInput");
    },
    querySelections() {
      this.loading = true;
      var that = this;
      api.keywords
        .list()
        .then((results) => {
          that.items = results.data;
          that.loading = false;
        })
        .catch((e) => {
          console.dir(e);
        });
    },
  },
};
</script>