<template>
  <v-form id="search" ref="form" @keyup.native.enter="submit">
    <v-container>
       <v-row>
        <v-col cols="12">
          <v-text-field v-model="fulltext" label="All fields" dense></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="title" label="Title" dense></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="author" label="Author" dense></v-text-field>
        </v-col>
      </v-row>
     <!-- <v-row>
        <v-col cols="12">
          <v-text-field v-model="citation" label="Full Citation" dense></v-text-field>
        </v-col>
      </v-row>-->
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="yearStart" label="Year From" dense></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="yearEnd" label="Year To" dense></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Keywords @select="updateKeywords" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Groups @select="updateGroups" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Methods @select="updateMethods" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Species @select="updateSpecies" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Regions @select="updateRegions" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <!-- Full Text Search -->
        </v-col>
      </v-row>
      <v-btn class="mt-6 mr-4" @click="submit"> submit </v-btn>

      <v-btn class="mt-6 mr-4" @click="reset"> reset </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import Keywords from "@/components/Keywords";
import Groups from "@/components/Groups";
import Methods from "../components/Methods.vue";
import Species from "../components/Species.vue";
import Regions from "../components/Regions.vue";

export default {
  name: "SearchForm",
  components: {
    Keywords,
    Groups,
    Methods,
    Species,
    Regions,
  },
  data() {
    return {
      fulltext: "",
      title: "",
      author: "",
      citation: "",
      keywords: [],
      groups: [],
      methods: [],
      species: [],
      regions: [],
      yearStart: null,
      yearEnd: null,
      newSearch: null
    };
  },
  methods: {
    updateKeywords(v) {
      this.keywords = v;
    },
    updateGroups(v) {
      this.groups = v;
    },
    updateMethods(v) {
      this.methods = v;
    },
    updateSpecies(v) {
      this.species = v;
    },
    updateRegions(v) {
      this.regions = v;
    },
    submit() {
      const searchTerms = {
        fulltext: this.fulltext,
        title: this.title,
        author: this.author,
        citation: this.citation,
        keywords: this.keywords,
        groups: this.groups,
        methods: this.methods,
        species: this.species,
        regions: this.regions,
        yearStart: this.yearStart,
        yearEnd: this.yearEnd,
      }
      this.$emit("submit", searchTerms);
    },
    reset() {
      this.$refs.form.reset();
      this.$emit("reset");
    },
  },
};
</script>

<style scoped>

input {background: white;}

.compact-form {
  transform: scale(0.875);
  transform-origin: left;
}
</style>