var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"expanded":_vm.expanded,"server-items-length":_vm.total,"items-per-page":10,"loading":_vm.loading,"show-expand":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.link)?_c('div',[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v("Link")])]):_vm._e()])]}},{key:"item.keywords",fn:function(ref){
var item = ref.item;
return _vm._l((item.keywords),function(value,index){return _c('div',[_vm._v(" "+_vm._s(value.keywords_id.name)+" "),(index+1 < item.keywords.length)?_c('span',[_vm._v(",")]):_vm._e()])})}},{key:"item.methods",fn:function(ref){
var item = ref.item;
return _vm._l((item.methods),function(value,index){return _c('div',[_vm._v(" "+_vm._s(value.methods_id.name)+" "),(index+1 < item.methods.length)?_c('span',[_vm._v(",")]):_vm._e()])})}},{key:"item.groups",fn:function(ref){
var item = ref.item;
return _vm._l((item.groups),function(value,index){return _c('div',[_vm._v(" "+_vm._s(value.groups_id.name)+" "),(index+1 < item.groups.length)?_c('span',[_vm._v(",")]):_vm._e()])})}},{key:"item.species",fn:function(ref){
var item = ref.item;
return _vm._l((item.species),function(value,index){return _c('div',[_vm._v(" "+_vm._s(value.species_id.name)+" "),(index+1 < item.species.length)?_c('span',[_vm._v(",")]):_vm._e()])})}},{key:"item.regions",fn:function(ref){
var item = ref.item;
return _vm._l((item.regions),function(value,index){return _c('div',[_vm._v(" "+_vm._s(value.regions_id.name)+" "),(index+1 < item.regions.length)?_c('span',[_vm._v(",")]):_vm._e()])})}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"expand",attrs:{"colspan":headers.length}},[(item.source)?_c('div',[_vm._v("Source: "+_vm._s(item.source))]):_vm._e(),(item.full_citiation)?_c('div',[_vm._v("Full Citation: "+_vm._s(item.full_citation))]):_vm._e(),(item.reference_materials)?_c('div',[_vm._v("Reference Materials: "+_vm._s(item.reference_materials))]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }