import axios from 'axios';

export const BASE = 'https://lktks.npfmc.org';
export const DOCUMENTS = '/items/documents';
export const KEYWORDS = '/items/keywords';
export const USERGROUPS = '/items/groups';
export const METHODS = '/items/methods';
export const SPECIES = '/items/species';
export const REGIONS = '/items/regions';

//setup axios
const http = axios.create({
    baseURL: BASE,
});

//flatten the server response so we don't have to write data.data everywhere
http.interceptors.response.use(function (response) {
    if (response.data.meta) response.meta = response.data.meta;
    if (response.data.error) response.error = response.data.error;
    response.data = response.data.data;
    return response;
});

const documents = {
  list: params => http.get(DOCUMENTS, { params })
};

const keywords = {
    list: params => http.get(KEYWORDS, { params })
};

const userGroups = {
  list: params => http.get(USERGROUPS, { params })
};

const methods = {
  list: params => http.get(METHODS, { params })
};

const species = {
  list: params => http.get(SPECIES, { params })
};

const regions = {
  list: params => http.get(REGIONS, { params })
};

export default {
  keywords,
  documents,
  userGroups,
  methods,
  species,
  regions
}