<template>
  <v-container fluid>
    <v-row>
     <div id="info">
      Additional suggestions for sources of local knowledge (LK), traditional knowledge (TK), 
      the social science of LK and TK, as well as information about the subsistence way of
      life can be sent to <a href="mailto:npfmc.lktks@gmail.com">npfmc.lktks@gmail.com</a>
    </div>
    </v-row>
    <v-row>
      <v-col md="3">
        <SearchForm @submit="onSubmit" @reset="onReset"/>
      </v-col>
      <v-col md="9">
        <ResultsTable v-bind:filter="filter" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchForm from "../components/SearchForm.vue";
import ResultsTable from "../components/ResultsTable.vue";

export default {
  name: "Home",
  metaInfo: {
    title:'LKTKS Information'
  },
  data() {
    return {
      filter: {},
    };
  },
  components: {
    SearchForm,
    ResultsTable,
  },
  methods: {
    onSubmit(values) {
      var filter = {};
      
      //console.log('values:');
      //console.dir(values);

       if (values.fulltext) {
        filter._or = [{
          title : { _contains: values.fulltext }
        },{
          author : { _contains: values.fulltext },
        },{
          full_citation : { _contains: values.fulltext },
        },{
          year : { _contains: values.fulltext }
        },{
          keywords: {
              keywords_id: {
                  name: { _contains: values.fulltext }
              }
    
          }
        },{
          groups: {
              groups_id: {
                  name: { _contains: values.fulltext }
              }
    
          }
        },{
          methods: {
              methods_id: {
                  name: { _contains: values.fulltext }
              }
    
          }
        },{
          species: {
            species_id: {
                  name: { _contains: values.fulltext }
              }
    
          }
        },{
          regions: {
              regions_id: {
                  name: { _contains: values.fulltext }
              }
    
          }
        }]
      }

      if (values.title) {
        filter.title = { _contains: values.title };
      }
      if (values.author) {
        filter.author = { _contains: values.author };
      }
      /*if (values.citation) {
        filter.full_citation = { _contains: values.citation };
      }*/
      
      if (values.keywords.length) {
        filter.keywords = {};
        filter.keywords.keywords_id = { _in: values.keywords };
      }

      if (values.groups.length) {
        filter.groups = {};
        filter.groups.groups_id = { _in: values.groups };
      }

      if (values.methods.length) {
        filter.methods = {};
        filter.methods.methods_id = { _in: values.methods };
      }
      if (values.species.length) {
        filter.species = {};
        filter.species.species_id = { _in: values.species };
      }

      if (values.regions.length) {
        filter.regions = {};
        filter.regions.regions_id = { _in: values.regions };
      }

      if (values.yearStart && values.yearEnd) {
        filter.year = { _between: [values.yearStart, values.yearEnd] };
      }

      this.filter.newSearch = true;

      //console.log('Filter:');
      //console.dir(filter);
      
      this.filter = filter;
    },
    onReset(){
      this.filter = {};
    }
  },
};
</script>
<style scoped>
  #info { padding: 10px; font-family: roboto; font-size: 14px; }
  .row { background: #fff; margin-bottom:10px; border-radius:4px; box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;}
</style>