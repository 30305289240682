<template>

  <v-app id="app">

    <div id="logo"><a href="https://www.npfmc.org/"></a></div>
    
    <v-main id="main">
      <router-view/>
    </v-main>

  </v-app>
  
</template>

<script>

export default {
  name: 'App',
  metaInfo: {
    title:'LKTKS Information'
  },
  data: () => ({
    //
  }),
};
</script>

<style scoped>
@media screen {
  #main { margin: 0px 20px; }
}
  #app {background: url('~@/assets/bg.jpg') no-repeat top center #2a6aa7; background-size:100%;}
  #logo {margin:0px 0px 10px 0px;}
  #logo a { float:left; height:105px; width:460px; background: url('~@/assets/logo.png') no-repeat left; max-width: 100%; max-height: 90px; background-size: 100% auto;}
  #main {background: transparent; }
</style>