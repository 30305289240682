<style>
.expand > * {
  margin:2px;
}
</style>
<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="options"
    :expanded.sync="expanded"
    :server-items-length="total"
    :items-per-page="10"
    :loading="loading"
    class="elevation-1"    
    show-expand
    
  >
    <!-- eslint-disable vue/valid-v-slot vue/require-v-for-key vue/no-unused-vars -->
    <template v-slot:item.title="{ item }">
      {{ item.title }}
    </template>

    <template v-slot:item.link="{ item }">
      <div>
        <div v-if="item.link"><a :href="item.link" target="_blank">Link</a></div>
      </div>
    </template>

    <template v-slot:item.keywords="{ item }">
      <div v-for="(value, index) in item.keywords">
        {{ value.keywords_id.name }}
         <span v-if="index+1 < item.keywords.length">,</span>
      </div>
    </template>

    <template v-slot:item.methods="{ item }">
          <div v-for="(value, index) in item.methods">
        {{ value.methods_id.name }}
         <span v-if="index+1 < item.methods.length">,</span>
      </div>
    </template>


    <template v-slot:item.groups="{ item }">
          <div v-for="(value, index) in item.groups">
        {{ value.groups_id.name }}
         <span v-if="index+1 < item.groups.length">,</span>
      </div>
    </template>

    <template v-slot:item.species="{ item }">
          <div v-for="(value, index) in item.species">
        {{ value.species_id.name }}
         <span v-if="index+1 < item.species.length">,</span>
      </div>
    </template>

    <template v-slot:item.regions="{ item }">
          <div v-for="(value, index) in item.regions">
        {{ value.regions_id.name }}
         <span v-if="index+1 < item.regions.length">,</span>
      </div>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="expand">
        <div v-if="item.source">Source: {{ item.source }}</div>
        <div v-if="item.full_citiation">Full Citation: {{ item.full_citation }}</div>
        <div v-if="item.reference_materials">Reference Materials: {{ item.reference_materials }}</div>
      </td>
    </template>

  </v-data-table>
</template>

<script>
import api from "@/common/api";

export default {
  name: "ResultsTable",
  props: {
    filter: Object,
  },
  data() {
    return {
      total: 0,
      items: [],
      loading: true,
      options: {},
      headers: [
       
        { text: "Title", value: "title"},
        { text: "Author", value: "author", width:200 },
        { text: "Year", value: "year", width: 40},
        { text: "Link", value: "link", width:40},
        { text: '', value: 'data-table-expand', width:50},
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    filter: {
      handler() {
        this.expanded = []; //close all the expanded rows
        this.options.page = 1; //return to the first page
        this.getDataFromApi();
      },
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      
      this.loading = true;
      var that = this;
    
      var sort = this.options.sortBy[0];
      if(sort){
        sort = (this.options.sortDesc[0] ? '-' : '') + sort;
      }

      api.documents
        .list({
          fields: "*,keywords.keywords_id.name,methods.methods_id.name,groups.groups_id.name,species.species_id.name,regions.regions_id.name",
          filter: this.filter,
          meta:'*',
          limit:this.options.itemsPerPage,
          page:this.options.page,
          sort: sort
        })
        .then((results) => {
          that.items = results.data;
          that.total = results.meta.filter_count;
          that.loading = false;
           
        })
        .catch((e) => {
          console.dir(e);
        });
    },
  },
};
</script>